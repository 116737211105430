/* eslint-disable prettier/prettier */
import axiosInstance from 'src/utils/axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildQueryString } from 'src/utils/function';

interface UserState {
  usersData: {
    count: number;
    rows: any;
  };
  usersDataloading: boolean;
  getUserData: any;
  getUserDataLoading: boolean;
  updateUserLoading: boolean;
  updateUserError: boolean;
  updateUserSuccess: boolean;
  fetchUserData: any;
  fetchUserDataLoading: boolean;
  fetchUserDataSuccess: boolean;
  fetchUserDataError: boolean;
  updateFlashCardSuccess: boolean;
  updateFlashCardError: boolean;
  updateFlashCardLoading: boolean;
  updateUserIsblockLoading: boolean;
  updateUserIsblockSuccess: boolean;
  updateUserIsblockError: boolean;
  deleteUserSuccess: boolean;
  deleteUserError: boolean;
  deleteUserLoading: boolean;
  deleteFlashCardSuccess: boolean;
  deleteFlashCardError: boolean;
  deleteFlashCardLoading: boolean;
}

const initialState: UserState = {
  usersData: {
    count: 0,
    rows: [],
  },
  usersDataloading: false,
  getUserData: {},
  getUserDataLoading: false,
  updateUserLoading: false,
  updateUserError: false,
  updateUserSuccess: false,
  fetchUserData: {},
  fetchUserDataError: false,
  fetchUserDataLoading: false,
  fetchUserDataSuccess: false,
  updateFlashCardError: false,
  updateFlashCardSuccess: false,
  updateFlashCardLoading: false,
  updateUserIsblockLoading: false,
  updateUserIsblockSuccess: false,
  updateUserIsblockError: false,
  deleteUserError: false,
  deleteUserSuccess: false,
  deleteUserLoading: false,
  deleteFlashCardSuccess: false,
  deleteFlashCardError: false,
  deleteFlashCardLoading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startUserloading(state) {
      state.usersDataloading = true;
    },
    getUserData(state, action: PayloadAction<any>) {
      state.usersDataloading = false;
      state.usersData = action.payload;
    },
    stopUserLoading(state) {
      state.usersDataloading = false;
    },
    startGetUserloading(state) {
      state.getUserDataLoading = true;
    },
    getUserMyData(state, action: PayloadAction<any>) {
      state.getUserDataLoading = false;
      state.getUserData = action.payload;
    },

    stopGetUserLoading(state) {
      state.getUserDataLoading = false;
    },
    startUserByIdloading(state) {
      state.fetchUserDataLoading = true;
    },
    getUserByIdData(state, action: PayloadAction<any>) {
      state.fetchUserDataLoading = false;
      state.fetchUserData = action.payload;
    },
    stopUserByIdLoading(state) {
      state.fetchUserDataLoading = false;
    },
    updateUserLoading(state) {
      state.updateUserLoading = true;
    },
    updateUserSuccess(state) {
      state.updateUserSuccess = true;
      state.updateUserLoading = false;
    },
    updateUserError(state) {
      state.updateUserError = true;
      state.updateUserLoading = false;
    },
    updateUserIsblockLoading(state) {
      state.updateUserIsblockLoading = true;
    },
    updateUserIsblockSuccess(state) {
      state.updateUserIsblockSuccess = true;
      state.updateUserIsblockLoading = false;
    },
    updateUserIsblockError(state) {
      state.updateUserIsblockError = true;
      state.updateUserIsblockLoading = false;
    },
    updateFlashcardByIdLoading(state) {
      state.updateFlashCardLoading = true;
    },
    updateFlashcardByIdSuccess(state) {
      state.updateFlashCardSuccess = true;
      state.updateFlashCardLoading = false;
    },
    updateFlashcardByIdError(state) {
      state.updateFlashCardError = true;
      state.updateFlashCardLoading = false;
    },
    deleteUserLoading(state) {
      state.deleteUserLoading = true;
    },
    deleteUserSuccess(state) {
      state.deleteUserSuccess = true;
      state.deleteUserLoading = false;
    },
    deleteUserError(state) {
      state.deleteUserError = true;
      state.deleteUserLoading = false;
    },
    clearDeleteUserData(state) {
      state.deleteFlashCardLoading = false;
      state.deleteFlashCardError = false;
      state.deleteFlashCardSuccess = false;
    },
    deleteFlashCardByIdLoading(state) {
      state.deleteFlashCardLoading = true;
    },
    deleteFlashCardByIdSuccess(state) {
      state.deleteFlashCardSuccess = true;
      state.deleteFlashCardLoading = false;
    },
    deleteFlashCardByIdError(state) {
      state.deleteFlashCardError = true;
      state.deleteFlashCardLoading = false;
    },
  },
});

export const {
  startUserloading,
  getUserData,
  stopUserLoading,
  updateUserLoading,
  updateUserSuccess,
  updateUserError,
  updateUserIsblockLoading,
  updateUserIsblockSuccess,
  updateUserIsblockError,
  updateFlashcardByIdError,
  updateFlashcardByIdLoading,
  updateFlashcardByIdSuccess,
  deleteUserError,
  deleteUserSuccess,
  deleteUserLoading,
  getUserByIdData,
  startUserByIdloading,
  stopUserByIdLoading,
  getUserMyData,
  startGetUserloading,
  stopGetUserLoading,
  deleteFlashCardByIdError,
  deleteFlashCardByIdLoading,
  deleteFlashCardByIdSuccess,
  clearDeleteUserData,
} = userSlice.actions;

export default userSlice.reducer;

export const getAllUsers =
  (query: Params): any =>
  async (dispatch: any) => {
    try {
      dispatch(startUserloading());
      const response = await axiosInstance.get(`/admins/getalluser${buildQueryString(query)}`); // Replace with your API endpoint
      dispatch(getUserData(response.data.data));
    } catch (error) {
      dispatch(stopUserLoading(error.message));
    }
  };

export const getUser = (): any => async (dispatch: any) => {
  try {
    dispatch(startGetUserloading());
    const response = await axiosInstance.get(`/users/getuser`); // Replace with your API endpoint
    dispatch(getUserMyData(response.data));
  } catch (error) {
    dispatch(stopGetUserLoading(error.message));
  }
};

export const updateUser =
  (data: any, enqueueSnackbar): any =>
  async (dispatch: any) => {
    try {
      dispatch(updateUserLoading());
      await axiosInstance.patch(`/users/updateuser`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('sucess');
      dispatch(updateUserSuccess());
      // const response = await axiosInstance.patch(`/users/updateuser`); // Replace with your API endpoint
      // dispatch(getUserMyData(response.data));
    } catch (error) {
      console.log('fail', error);
      dispatch(updateUserError(error.message));
    }
  };

export const updateUserPassword =
  (data: any): any =>
  async (dispatch: any) => {
    try {
      dispatch(updateUserLoading());
      const res = await axiosInstance.post(
        `/admins/setNewPasswordByAdmin`,
        data
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
      );
      console.log('sucess', res);
      dispatch(updateUserSuccess());
      // const response = await axiosInstance.patch(`/users/updateuser`); // Replace with your API endpoint
      // dispatch(getUserMyData(response.data));
    } catch (error) {
      console.log('fail', error);
      dispatch(updateUserError(error.message));
    }
  };
export const updateIsBlockedUser =
  (data: any, id: any): any =>
  async (dispatch: any) => {
    try {
      dispatch(updateUserIsblockLoading());
      await axiosInstance.patch(`/admins/blockuser/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('sucess');
      dispatch(updateUserIsblockSuccess());
      // const response = await axiosInstance.patch(`/users/updateuser`); // Replace with your API endpoint
      // dispatch(getUserMyData(response.data));
    } catch (error) {
      console.log('fail', error?.message);
      dispatch(updateUserIsblockError(error.message));
    }
  };

export const updateFlashCardById =
  (data: any, id: number): any =>
  async (dispatch: any) => {
    try {
      dispatch(updateFlashcardByIdLoading());
      await axiosInstance.patch(`/flashcard/updateflashcardbyid/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }); // Replace with your API endpoint
      dispatch(updateFlashcardByIdSuccess());
    } catch (error) {
      dispatch(updateFlashcardByIdError());
    }
  };

export const getUserById =
  (id: number): any =>
  async (dispatch: any) => {
    try {
      dispatch(startUserByIdloading());
      const response = await axiosInstance.get(`/users/${id}`); // Replace with your API endpoint
      dispatch(getUserByIdData(response.data));
    } catch (error) {
      dispatch(stopUserByIdLoading(error.message));
    }
  };

export const deleteUser =
  (id: number, enqueueSnackbar: any, refresher?: Function): any =>
  async (dispatch: any) => {
    try {
      dispatch(deleteUserLoading());
      await axiosInstance.patch(`/admins/deleteuser/${id}`); // Replace with your API endpoint
      dispatch(deleteUserSuccess());
      if (refresher) {
        refresher();
      } else {
        dispatch(getAllUsers({ page: 1, pageSize: 10 }));
      }
    } catch (error) {
      dispatch(deleteUserError());
      enqueueSnackbar(error.message ?? 'Something went wrong', { variant: 'error' });
    }
  };

export const deleteFlashCardById =
  (id: number): any =>
  async (dispatch: any) => {
    try {
      dispatch(deleteFlashCardByIdLoading());
      await axiosInstance.patch(`/flashcard/deleteflashcardbyid/${id}`); // Replace with your API endpoint
      dispatch(deleteFlashCardByIdSuccess());
    } catch (error) {
      dispatch(deleteFlashCardByIdError());
    }
  };
