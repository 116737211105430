/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prettier/prettier */
import axiosInstance from 'src/utils/axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildQueryString } from 'src/utils/function';

interface LanguageState {
  languageData: {
    count: number;
    rows: any;
  };
  languageDataloading: boolean;
  languageWithFlashcardData: {
    count: number;
    rows: any;
  };
  languageWithFlashcardDataLoading: any;
  fetchLanguageData: any;
  fetchLanguageDataLoading: boolean;
  fetchLanguageDataSuccess: boolean;
  fetchLanguageDataError: boolean;
  createLanguageSuccess: boolean;
  createLanguageError: boolean;
  createLanguageLoading: boolean;
  updateLanguageSuccess: boolean;
  updateLanguageError: boolean;
  updateLanguageLoading: boolean;
  deleteLanguageSuccess: boolean;
  deleteLanguageError: boolean;
  deleteLanguageLoading: boolean;
}

const initialState: LanguageState = {
  languageData: {
    count: 0,
    rows: [],
  },
  languageDataloading: false,
  languageWithFlashcardData: {
    count: 0,
    rows: [],
  },
  languageWithFlashcardDataLoading: false,
  fetchLanguageData: {},
  fetchLanguageDataError: false,
  fetchLanguageDataLoading: false,
  fetchLanguageDataSuccess: false,
  createLanguageError: false,
  createLanguageSuccess: false,
  createLanguageLoading: false,
  updateLanguageError: false,
  updateLanguageSuccess: false,
  updateLanguageLoading: false,
  deleteLanguageError: false,
  deleteLanguageSuccess: false,
  deleteLanguageLoading: false,
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    startLanguageloading(state) {
      state.languageDataloading = true;
    },
    getLanguageData(state, action: PayloadAction<any>) {
      state.languageDataloading = false;
      state.languageData = action.payload;
    },
    stopLanguageLoading(state) {
      state.languageDataloading = false;
    },
    startLanguageWithFlashcardloading(state) {
      state.languageWithFlashcardDataLoading = true;
    },
    getLanguageWithFlashcardData(state, action: PayloadAction<any>) {
      state.languageWithFlashcardDataLoading = false;
      state.languageWithFlashcardData = action.payload;
    },
    stopLanguageWithFlashcardLoading(state) {
      state.languageWithFlashcardDataLoading = false;
    },
    startLanguageByIdloading(state) {
      state.fetchLanguageDataLoading = true;
    },
    getLanguageByIdData(state, action: PayloadAction<any>) {
      state.fetchLanguageDataLoading = false;
      state.fetchLanguageData = action.payload;
    },
    stopLanguageByIdLoading(state) {
      state.fetchLanguageDataLoading = false;
    },
    createLanguageLoading(state) {
      state.createLanguageLoading = true;
    },
    createLanguageSuccess(state) {
      state.createLanguageSuccess = true;
      state.createLanguageLoading = false;
    },
    createLanguageError(state) {
      state.createLanguageError = true;
      state.createLanguageLoading = false;
    },
    clearCreateLanguageData(state) {
      state.createLanguageSuccess = false;
      state.createLanguageLoading = false;
      state.createLanguageError = false;
    },
    updateLanguageLoading(state) {
      state.updateLanguageLoading = true;
    },
    updateLanguageSuccess(state) {
      state.updateLanguageSuccess = true;
      state.updateLanguageLoading = false;
    },
    updateLanguageError(state) {
      state.updateLanguageError = true;
      state.updateLanguageLoading = false;
    },
    clearUpdateLanguageData(state) {
      state.updateLanguageLoading = false;
      state.updateLanguageError = false;
      state.updateLanguageSuccess = false;
    },
    deleteLanguageLoading(state) {
      state.deleteLanguageLoading = true;
    },
    deleteLanguageSuccess(state) {
      state.deleteLanguageSuccess = true;
      state.deleteLanguageLoading = false;
    },
    deleteLanguageError(state) {
      state.deleteLanguageError = true;
      state.deleteLanguageLoading = false;
    },
    clearDeleteLanguageData(state) {
      state.deleteLanguageError = false;
      state.deleteLanguageLoading = false;
      state.deleteLanguageSuccess = false;
    },
  },
});

export const {
  startLanguageloading,
  getLanguageData,
  stopLanguageLoading,
  createLanguageError,
  createLanguageSuccess,
  createLanguageLoading,
  updateLanguageError,
  updateLanguageSuccess,
  updateLanguageLoading,
  deleteLanguageError,
  deleteLanguageSuccess,
  deleteLanguageLoading,
  getLanguageWithFlashcardData,
  startLanguageWithFlashcardloading,
  stopLanguageWithFlashcardLoading,
  getLanguageByIdData,
  startLanguageByIdloading,
  stopLanguageByIdLoading,
  clearCreateLanguageData,
  clearDeleteLanguageData,
  clearUpdateLanguageData,
} = languageSlice.actions;

export default languageSlice.reducer;

export const getAllLanguages =
  (query: Params): any =>
  async (dispatch: any) => {
    try {
      dispatch(startLanguageloading());
      const response = await axiosInstance.get(`/language${buildQueryString(query)}`); // Replace with your API endpoint
      dispatch(getLanguageData(response.data.data));
    } catch (error) {
      dispatch(stopLanguageLoading(error.message));
    }
  };

export const createLanguage =
  (data: any, enqueueSnackbar: any, onClose: any, refresher?: Function): any =>
  async (dispatch: any) => {
    try {
      dispatch(createLanguageLoading());
      const dd = await axiosInstance.post(`/language`, data); // Replace with your API endpoint

      if (dd?.data?.status) {
        enqueueSnackbar('Language created', { variant: 'success' });
        onClose({
          successs: true,
        });
        if (refresher) {
          refresher();
        } else {
          dispatch(getAllLanguages({ page: 1, pageSize: 10 }));
        }
      }
      dispatch(createLanguageSuccess());
    } catch (error) {
      console.log(error?.message, 'vdddfdc');
      enqueueSnackbar(error.message ?? 'Something went wrong', { variant: 'error' });
      dispatch(createLanguageError());
    }
  };

export const updateLanguage =
  (data: any, id: number, enqueueSnackbar: any, refresher?: Function): any =>
  async (dispatch: any) => {
    try {
      dispatch(updateLanguageLoading());
      await axiosInstance.patch(`/language/${id}`, data); // Replace with your API endpoint
      enqueueSnackbar('Language updated', { variant: 'success' });
      dispatch(updateLanguageSuccess());
      if (refresher) {
        refresher();
      } else {
        dispatch(getAllLanguages({ page: 1, pageSize: 10 }));
      }
    } catch (error) {
      enqueueSnackbar(error.message ?? 'Something went wrong', { variant: 'error' });
      dispatch(updateLanguageError());
    }
  };

// export const getAllLanguageWithFlashcard =
//   (page: number, pageSize: number): any =>
//   async (dispatch: any) => {
//     try {
//       dispatch(startLanguageWithFlashcardloading());
//       const response = await axiosInstance.get(
//         `/language/admin/languageflashcard?page=${page}&pageSize=${pageSize}`
//       ); // Replace with your API endpoint
//       dispatch(getLanguageWithFlashcardData(response.data.data));
//     } catch (error) {
//       dispatch(stopLanguageWithFlashcardLoading(error.message));
//     }
//   };

export const getLanguageById =
  (id: number): any =>
  async (dispatch: any) => {
    try {
      dispatch(startLanguageByIdloading());
      const response = await axiosInstance.get(`/language/${id}`); // Replace with your API endpoint
      dispatch(getLanguageByIdData(response.data));
    } catch (error) {
      dispatch(stopLanguageByIdLoading(error.message));
    }
  };

export const deleteLanguage =
  (id: number, enqueueSnackbar: any, refresher?: Function): any =>
  async (dispatch: any) => {
    try {
      dispatch(deleteLanguageLoading());
      await axiosInstance.delete(`/language/${id}`); // Replace with your API endpoint
      dispatch(deleteLanguageSuccess());
      if (refresher) {
        refresher();
      } else {
        dispatch(getAllLanguages({ page: 1, pageSize: 10 }));
      }
    } catch (error) {
      dispatch(deleteLanguageError());
      enqueueSnackbar(error.message ?? 'Something went wrong', { variant: 'error' });
    }
  };
