/* eslint-disable prettier/prettier */
import axiosInstance from 'src/utils/axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { buildQueryString } from 'src/utils/function';

interface GroupState {
  groupData: {
    count: number;
    rows: any;
    data: any;
  };
  groupDataLoading: boolean;
  groupWithFlashcardData: {
    count: number;
    rows: any;
  };
  groupWithFlashcardDataLoading: boolean;
  fetchGroupData: any;
  fetchGroupDataLoading: boolean;
  fetchGroupDataSuccess: boolean;
  fetchGroupDataError: boolean;
  createGroupSuccess: boolean;
  createGroupError: boolean;
  createGroupLoading: boolean;
  updateGroupSuccess: boolean;
  updateGroupError: boolean;
  updateGroupLoading: boolean;
  deleteGroupSuccess: boolean;
  deleteGroupError: boolean;
  deleteGroupLoading: boolean;
}

const initialState: GroupState = {
  groupData: {
    count: 0,
    rows: [],
    data: [],
  },
  groupDataLoading: false,
  groupWithFlashcardData: {
    count: 0,
    rows: [],
  },
  groupWithFlashcardDataLoading: false,
  fetchGroupData: {},
  fetchGroupDataError: false,
  fetchGroupDataLoading: false,
  fetchGroupDataSuccess: false,
  createGroupError: false,
  createGroupSuccess: false,
  createGroupLoading: false,
  updateGroupError: false,
  updateGroupSuccess: false,
  updateGroupLoading: false,
  deleteGroupError: false,
  deleteGroupSuccess: false,
  deleteGroupLoading: false,
};

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    startGroupLoading(state) {
      state.groupDataLoading = true;
    },
    getGroupData(state, action: PayloadAction<any>) {
      state.groupDataLoading = false;
      state.groupData = action.payload;
    },
    stopGroupLoading(state) {
      state.groupDataLoading = false;
    },
    startGroupWithFlashcardLoading(state) {
      state.groupWithFlashcardDataLoading = true;
    },
    updateFlashcardGroupCount(state, action: PayloadAction<{ groupId: number; delta: number }>) {
      const { groupId, delta } = action.payload;
      const group = state?.groupData?.rows?.find((row) => row?.id === groupId);
      if (group) {
        group.flashCardCount += delta;
      }
    },
    getGroupWithFlashcardData(state, action: PayloadAction<any>) {
      state.groupWithFlashcardDataLoading = false;
      state.groupWithFlashcardData = action.payload;
    },
    stopGroupWithFlashcardLoading(state) {
      state.groupWithFlashcardDataLoading = false;
    },
    startGroupByIdLoading(state) {
      state.fetchGroupDataLoading = true;
    },
    getGroupByIdData(state, action: PayloadAction<any>) {
      state.fetchGroupDataLoading = false;
      state.fetchGroupData = action.payload;
    },
    stopGroupByIdLoading(state) {
      state.fetchGroupDataLoading = false;
    },
    createGroupLoading(state) {
      state.createGroupLoading = true;
    },
    createGroupSuccess(state) {
      state.createGroupSuccess = true;
      state.createGroupLoading = false;
    },
    createGroupError(state) {
      state.createGroupError = true;
      state.createGroupLoading = false;
    },
    clearCreateGroupData(state) {
      state.createGroupSuccess = false;
      state.createGroupLoading = false;
      state.createGroupError = false;
    },
    updateGroupLoading(state) {
      state.updateGroupLoading = true;
    },
    updateGroupSuccess(state) {
      state.updateGroupSuccess = true;
      state.updateGroupLoading = false;
    },
    updateGroupError(state) {
      state.updateGroupError = true;
      state.updateGroupLoading = false;
    },
    clearUpdateGroupData(state) {
      state.updateGroupLoading = false;
      state.updateGroupError = false;
      state.updateGroupSuccess = false;
    },
    deleteGroupLoading(state) {
      state.deleteGroupLoading = true;
    },
    deleteGroupSuccess(state) {
      state.deleteGroupSuccess = true;
      state.deleteGroupLoading = false;
    },
    deleteGroupError(state) {
      state.deleteGroupError = true;
      state.deleteGroupLoading = false;
    },
    clearDeleteGroupData(state) {
      state.deleteGroupError = false;
      state.deleteGroupLoading = false;
      state.deleteGroupSuccess = false;
    },
  },
});

export const {
  startGroupLoading,
  updateFlashcardGroupCount,
  getGroupData,
  stopGroupLoading,
  createGroupError,
  createGroupSuccess,
  createGroupLoading,
  updateGroupError,
  updateGroupSuccess,
  updateGroupLoading,
  deleteGroupError,
  deleteGroupSuccess,
  deleteGroupLoading,
  getGroupWithFlashcardData,
  startGroupWithFlashcardLoading,
  stopGroupWithFlashcardLoading,
  getGroupByIdData,
  startGroupByIdLoading,
  stopGroupByIdLoading,
  clearCreateGroupData,
  clearDeleteGroupData,
  clearUpdateGroupData,
} = groupSlice.actions;

export default groupSlice.reducer;

export const getAllGroups =
  (query: Params): any =>
  async (dispatch: any) => {
    try {
      dispatch(startGroupLoading());
      const response = await axiosInstance.get(`/group/admin/all${buildQueryString(query)}`);
      dispatch(getGroupData(response.data.data));
    } catch (error) {
      dispatch(stopGroupLoading(error.message));
    }
  };

export const createGroup =
  (data: any, enqueueSnackbar: any, refresher?: Function): any =>
  async (dispatch: any) => {
    try {
      dispatch(createGroupLoading());
      dispatch(startGroupByIdLoading());
      await axiosInstance.post(`/group/admin`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(createGroupSuccess());
      if (refresher) {
        refresher();
      } else {
        dispatch(getAllGroups({ page: 1, pageSize: 10 }));
      }
      dispatch(startGroupByIdLoading());
    } catch (error) {
      dispatch(createGroupError());
      enqueueSnackbar(error.message ?? 'Something went wrong', { variant: 'error' });
    }
  };

export const updateGroup =
  (data: any, id: number, enqueueSnackbar: any, refresher?: Function): any =>
  async (dispatch: any) => {
    try {
      dispatch(updateGroupLoading());
      await axiosInstance.patch(`/group/admin/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(updateGroupSuccess());
      if (refresher) {
        refresher();
      } else {
        dispatch(getAllGroups({ page: 1, pageSize: 10 }));
      }
    } catch (error) {
      dispatch(updateGroupError());
      enqueueSnackbar(error.message ?? 'Something went wrong', { variant: 'error' });
    }
  };

export const updateGroupFlashCard =
  (data: any, id: number, enqueueSnackbar: any): any =>
  async (dispatch: any) => {
    try {
      dispatch(updateGroupLoading());
      await axiosInstance.patch(`/group/admin/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(updateGroupSuccess());
      // dispatch(getAllGroups({ page: 1, pageSize: 10 }));
    } catch (error) {
      dispatch(updateGroupError());
      enqueueSnackbar(error.message ?? 'Something went wrong', { variant: 'error' });
    }
  };
export const getAllGroupWithFlashcard =
  (query: Params): any =>
  async (dispatch: any) => {
    try {
      dispatch(startGroupWithFlashcardLoading());
      const response = await axiosInstance.get(`/groupflashcard${buildQueryString(query)}`);
      dispatch(getGroupWithFlashcardData(response.data.data));
    } catch (error) {
      dispatch(stopGroupWithFlashcardLoading(error.message));
    }
  };

export const getGroupById =
  (id: number): any =>
  async (dispatch: any) => {
    try {
      dispatch(startGroupByIdLoading());
      const response = await axiosInstance.get(`/group/${id}`);
      dispatch(getGroupByIdData(response.data));
    } catch (error) {
      dispatch(stopGroupByIdLoading(error.message));
    }
  };

export const deleteGroup =
  (id: number, refresher?: Function): any =>
  async (dispatch: any) => {
    try {
      dispatch(deleteGroupLoading());
      await axiosInstance.patch(`/group/admin/deletegroup/${id}`);
      dispatch(deleteGroupSuccess());
      if (refresher) {
        refresher();
      } else {
        dispatch(getAllGroups({ page: 1, pageSize: 10 }));
      }
    } catch (error) {
      dispatch(deleteGroupError(error?.message));
    }
  };
