/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useState } from 'react';
// @mui
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
    MenuItem,
    Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import { RHFUpload } from 'src/components/hook-form/RHFUpload';
import MultipleSelectField from 'src/components/modal/multipleselect-feild';
import { useDebouncedSearch } from 'src/hooks/use-debouncing';
import { getAllFlashCardForAdmin } from 'src/store/slices/flashCardSlice';
import { createGroup, updateGroup } from 'src/store/slices/groupListslice'; // Changed import to groupSlice
import { getAllLanguages } from 'src/store/slices/languageSlice';
import { RootState } from 'src/store/store';
import { sortByProperty } from 'src/utils/function';
import * as Yup from 'yup';

interface Props extends DialogProps {
    selectedRow?: any;
    open: boolean;
    onClose: VoidFunction;
    editId?: number;
    refresher?: Function
}

type FormValuesProps = {
    name: string;
    group_image: any;
    flash_card_array: any;

};

const FormSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    group_image: Yup.mixed().required('Image is required'),
    languageId: Yup.string().required('Language is required'),
    // group_image: Yup.mixed().required('Group image is required'),
    flash_card_array: Yup.array().required('Flash card is required'),
});

export default function GroupModal({ open, selectedRow = {}, editId = -1, onClose, refresher }: Props) {
    const { flashCardData } = useSelector((state: RootState) => state.flashCard);
    const { createGroupLoading, updateGroupLoading } = useSelector(
        (state: RootState) => state.group
    );
    const { languageData } = useSelector(
        (state: RootState) => state.language
    );
    const { enqueueSnackbar } = useSnackbar();
    // const { languageData } = useSelector((state: RootState) => state.language);
    const dispatch = useDispatch();
    const [url, setUrl] = useState<any>('');
    const [filteredFlashcards, setFilteredFlashcards] = useState<any[]>([]);
    const [selectedFlashCards, setSelectedFlashcards] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { results, loading } = useDebouncedSearch(searchQuery, 300);

    const handleInputChange = (event: any) => {
        setSearchQuery(event.target.value);
    };
    const defaultValues = {
        name: '',
        languageId: '',
        group_image: null,
        flash_card_array: [],
    };
    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(FormSchema),
        defaultValues,
    });

    const {
        reset,
        setValue,
        handleSubmit,
        watch,
        formState: { dirtyFields },
    } = methods;

    const onSubmit = async (data: any) => {
        // console.log(data, dirtyFields, data?.group_image?.path)
        const changedValues = Object.keys(dirtyFields).reduce((acc, key) => {
            acc[key] = data[key];
            return acc;
        }, {} as Partial<any>);
        const formData = new FormData();
        if (Object.keys(selectedRow)?.length > 0) {
            if (changedValues?.name) {
                formData.append('name', data.name);
            }
            if (changedValues?.group_image || data?.group_image?.path) {
                formData.append('group_image', data.group_image);
            }
            if (changedValues?.languageId) {
                formData.append("languageId", data?.languageId)
            }
            // if (selectedFlashCards?.length) {
            //     formData.append('flash_card_array', JSON.stringify(selectedFlashCards?.map(oneFlash => oneFlash?.id)));
            //     // formData.append('flash_card_array', JSON.stringify(data?.flash_card_array));
            // }
            await dispatch(updateGroup(formData, editId, updateGroup, refresher)); // Changed to updateGroup
        } else {
            formData.append('name', data.name);
            formData.append('group_image', data.group_image);
            // console.log(data)
            formData.append("languageId", data?.languageId)

            formData.append('flash_card_array', JSON.stringify(selectedFlashCards?.map(oneFlash => oneFlash?.id)));
            // formData.append('flash_card_array', JSON.stringify(data?.flash_card_array));
            await dispatch(createGroup(formData, enqueueSnackbar, refresher)); // Changed to createGroup
        }

    };

    const handleDrop = useCallback(
        (acceptedFiles: File[]) => {
            console.log(acceptedFiles)
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            setUrl(newFile);

            if (file) {
                setValue('group_image', file, { shouldValidate: true });
            }
        },
        [setValue]
    );

    const handleRemoveFile = () => {
        setValue('group_image', null);
    };

    useEffect(() => {
        if (Object.keys(selectedRow)?.length > 0) {
            const flashCards =
                selectedRow?.FlashCards?.length > 0
                    ? selectedRow?.FlashCards?.map((item: any) => item?.id)
                    : [];
            const data = {
                name: selectedRow?.name,
                group_image: selectedRow?.image,
                flash_card_array: flashCards ?? [],
                languageId: selectedRow?.languageId
            };
            reset(data);
            // Set the filtered flashcards based on the selected language
            const filtered = flashCardData?.rows?.filter(
                (flashcard: any) => flashcard.languageId === selectedRow.languageId
            );
            setFilteredFlashcards(filtered);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow, flashCardData]);

    useEffect(() => {
        dispatch(getAllFlashCardForAdmin({ page: 1, pageSize: 100 }));
        dispatch(getAllLanguages({ page: 1, pageSize: 1000 }));
    }, [dispatch]);

    // const selectedLanguage = watch('language');

    // useEffect(() => {
    // //   if (selectedLanguage) {
    // //     const filtered = flashCardData?.rows?.filter(
    // //       (flashcard: any) => flashcard.languageId === selectedLanguage
    // //     );
    //     // setFilteredFlashcards(filtered);
    // //   } else {
    // //     setFilteredFlashcards([]);
    // //   }
    // }, [selectedLanguage, flashCardData]);
    // console.log(selectedFlashCards)
    useEffect(() => {
        if (Object.keys(selectedRow)?.length > 0) {
            setValue('flash_card_array', selectedRow?.FlashCards?.map((item: any) => item?.id) ?? []);
        }
    }, [selectedRow, setValue]);
    // console.log(open, "Dialog state", filteredFlashcards)
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle
                sx={{
                    paddingTop: 2,
                    paddingX: 3,
                    paddingBottom: 0,
                }}
            >
                {Object.keys(selectedRow).length > 0 ? 'Edit Group' : 'Create Group'}
            </DialogTitle>
            <DialogContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid paddingY={2} container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <RHFTextField name="name" label="Enter Name" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelect
                                name="languageId"
                                label="Select language"
                                sx={{
                                    '& select': {
                                        background: '#fff !important',
                                        color: '#000',
                                        fontWeight: 600,
                                        fontFamily: 'Work Sans,sans-serif',
                                    },
                                }}
                            >
                                <MenuItem disabled value={""}>
                                    None
                                </MenuItem>
                                {sortByProperty(languageData?.rows, "name")?.map(singLang => <MenuItem key={singLang?.id} value={singLang?.id}>{singLang?.name}</MenuItem>)}
                            </RHFSelect>
                        </Grid>
                        {(Object.keys(selectedRow)?.length > 0 || !!!watch('languageId')) ? "" : <Grid item xs={12}>
                            <MultipleSelectField
                                query={{ languageId: watch('languageId') }}
                                selectedFlashCards={selectedFlashCards}
                                setSelectedFlashcards={setSelectedFlashcards}
                            />

                        </Grid>}
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" lineHeight={1} gutterBottom>
                                        Upload Image
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFUpload
                                        name="group_image"
                                        maxSize={3145728}
                                        files={url}
                                        onDrop={handleDrop}
                                        onDelete={handleRemoveFile}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    paddingY: 3,
                                    flexDirection: 'row',
                                    gap: 1,
                                }}
                            >
                                <Button onClick={onClose} color="inherit">
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    loading={
                                        Object.keys(selectedRow)?.length > 0 ? updateGroupLoading : createGroupLoading
                                    }
                                    loadingPosition="start"
                                    variant="contained"
                                >
                                    {updateGroupLoading === false && createGroupLoading === false && (
                                        <span>Save</span>
                                    )}
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </FormProvider>
            </DialogContent>
        </Dialog>
    );
}
