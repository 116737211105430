import { Dialog, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GroupFlashCard from 'src/components/cards/group-flash-card';
import FlashcardGroupAdd from 'src/components/common/FlashCardGroupAdd';
import Iconify from 'src/components/iconify/Iconify';
import { getAllFlashCardForAdmin } from 'src/store/slices/flashCardSlice';
import { updateFlashcardGroupCount, updateGroupFlashCard } from 'src/store/slices/groupListslice';
import axiosInstance from 'src/utils/axios';

const GroupViewModal = ({ row }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFlashCards, setSelectedFlashcards] = useState([]);
    const { flashCardData } = useSelector((state: RootState) => state.flashCard);

    const FetchData = async () => {
        const response = await axiosInstance.get(`/group/${row?.id}?page=1&pageSize=100&languageId=${row?.languageId}`);
        console.log(response)
        setData(response?.data?.data?.rows ?? []);
    };
    useEffect(() => {
        FetchData();
        dispatch(
            getAllFlashCardForAdmin({ page: 1, pageSize: 20, searchTerm, languageId: row?.languageId })
        );
    }, [refresh]);
    const addFlashCard = async (v) => {
        if (!v?.id) {
            return;
        }
        try {
            const res = await axiosInstance.post(`/group/addtogroup`, {
                group_id: row?.id,
                flashcard_id: v?.id,
            });
            console.log(res);
            dispatch(updateFlashcardGroupCount({ groupId: row?.id, delta: 1 }))

        } catch (error) {
            console.log(error);
        }
        setRefresh((prev) => !prev);
    };
    // useEffect(() => {

    //     // setSelectedFlashcards(prev => {
    //     //     if (prev?.length) {
    //     //         addFlashCard(prev?.[0]?.id)
    //     //         return []
    //     //     }
    //     //     // return []

    //     // })
    // }, [selectedFlashCards])
    return (
        <>
            <MenuItem
                onClick={() => {
                    setOpen(true);
                    // onEditRow();
                    // handleClosePopover();
                }}
            >
                <Iconify icon="eva:eye-outline" />
                View & Edit
            </MenuItem>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle
                    sx={{
                        paddingTop: 2,
                        paddingX: 3,
                        paddingBottom: 0,
                    }}
                >
                    View Group : {row?.name}
                </DialogTitle>
                <DialogContent>
                    <FlashcardGroupAdd
                        autocomplete
                        searchTerm={searchTerm}
                        setSearch={setSearchTerm}
                        placeholder="Search Flashcard to Add"
                        options={flashCardData?.rows?.filter(
                            el => !data?.some(existingFlashcard => existingFlashcard?.id === el?.id)
                        )}
                        loadMoreOptions={async () => {
                            await dispatch(
                                getAllFlashCardForAdmin({
                                    page: 1,
                                    pageSize: 20,
                                    searchTerm,
                                    languageId: row?.languageId,
                                })
                            );
                        }}
                        handleOptionChange={addFlashCard}
                    />
                    {data?.map((singleFalsh) => (
                        <GroupFlashCard
                            key={singleFalsh?.id}
                            itemData={singleFalsh}
                            onRemoveCard={async () => {
                                const formData = new FormData();
                                formData.append(
                                    'flash_card_array',
                                    JSON.stringify(
                                        data
                                            ?.map((oneFlash) => oneFlash?.id)
                                            ?.filter((oneFlash) => oneFlash !== singleFalsh?.id)
                                    )
                                );
                                await dispatch(updateGroupFlashCard(formData, row?.id, updateGroupFlashCard));
                                dispatch(updateFlashcardGroupCount({ groupId: row?.id, delta: -1 }))
                                setRefresh((prev) => !prev);
                            }}
                        />
                    ))}
                    {/* {[...data, ...data, ...data, ...data, ...data, ...data, ...data, ...data, ...data]?.map(singleFalsh => <GroupFlashCard key={singleFalsh?.id} itemData={singleFalsh} />)} */}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default GroupViewModal;
